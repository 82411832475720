.App {
  text-align: center;
  margin: 8px;
  height: calc(100% - 16px);
  width: calc(100% - 16px);
  touch-action: manipulation;
}

h1 {
  margin: 0;
  margin-bottom: 8px;
  font-size: 16px;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 48px - 24px);
}

.swipe {
  height: 48px;
  width: 100%;
  touch-action: none;
  background: gray;
}

.mode-tools {
  display: flex;
  justify-content: space-between;
  padding: 2px;
}

.cancel {
  height: 44px;
  width: 20%;
  background-color: white;
  border-radius: 3px;
  border: 1px solid #192d9e;
  font-size: 16px;
  color: black;
}

.done {
  height: 44px;
  width: 70%;
  background-color: #192d9e;
  border: 1px solid #192d9e;
  border-radius: 3px;
  font-size: 16px;
  color: white;
}

.done:disabled {
  background-color: darkgrey;
}