.records {
    display: flex;
    overflow: auto;
    gap: 32px;
    margin: 0 32px;
    height: 100vh;
}

.records .group {
    margin-bottom: 8px;
}

.group {
    display: flex;
    flex-direction: column;
    flex: 0 0 80vw;
    overflow: hidden;
}

.group-title {
    font-weight: bold;
}

.record-list {
    overflow-y: auto;
    scroll-behavior: smooth;
    max-height: 100vh;
}

.record-item {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.record-item-details {
    display: flex;
    gap: 8px;
    width: 100%;
    padding: 4px 0;
    overflow-x: hidden;
}

.record-sub-items {
    display: flex;
    flex-direction: column;
}

.record-sub-items .record-item-details {
    margin-left: 8px;
}

.record-time {
    flex: 0 0 20%;
}

.record-count {
    flex: 0 0 10%;
}

.record-note {
    flex: 0 0 auto;
}